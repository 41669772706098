.fuel .background{
    height: 800px;
    background-size: cover;
}
.s-96,.s-96142{
    font-size: 96px;
    line-height: 142px;
}
.fuel .bg-18{
    padding: 150px 0px;
}
.fuel .bg-18 img,
.fuel .item-img{
    width: 500px;
    height: 614px;
    object-fit: cover;
}
.fuel .s-24{
    line-height: 26px;
}
.wd-640{
    width: 640px;
}
.fuel .bg-18 p{
    margin: auto 0px auto 180px;
}
.fuel .bg-1f{
    padding: 174px 0px 150px;
}
.fuel .content{
    width: 1320px;
    margin: auto;
}
.fuel .content .text-ic{
    width: 30px;
    height: auto;
    object-fit: contain;
    margin: -10px 9px auto 0px;
}
.fuel h2.s-4871.mb-100{
    margin-bottom: 30px;
}
#text-image{
    width: 100%;
    text-align: center;
    align-content: center;
    margin-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mt-83{margin-top: 83px;}
.mb-150{margin-bottom: 150px;}

@media (max-width: 1399.98px) {
    div .fuel .content{
        max-width: 1000px;
        width: 92%;
    }
    div .fuel .item-img{
        width: 400px;
        height: 504px;
        object-fit: cover;
    }
    div .wd-640{
        width: 520px;
    }
    div .fuel .background , .header-image{
        height: 600px;
    }
    .fuel .s-6495 {
        font-size: 44px;
        line-height: 55px;
    }
    .fuel .s-96, .bg-content .s-96{
        font-size: 66px;
        line-height: 96px;
    }
    .fuel .s-2030,
    .bg-content .s-2030 {
        font-size: 16px;
        line-height: 26px;
    }
    .fuel .s-2030 p, .fuel .s-2030 span,
    .bg-content .s-2030 p, .bg-content .s-2030 span{
        font-size: 16px !important;
        line-height: 26px !important;
    }
    .bg-content .s-6495 {
        font-size: 44px;
        line-height: 65px;
    }
    .bg-content .s-4050 {
        font-size: 25px;
        line-height: 35px;
    }
    .fuel .s-2840 {
        font-size: 20px;
        line-height: 30px;
    }
    .fuel .s-3639 {
        font-size: 28px;
        line-height: 34px;
    }
    div .fuel .content .text-ic {
        width: 22px;
    }
    div .fuel .bg-18, .fuel .bg-1f {
        padding: 100px 0px;
    }
}

@media (max-width: 991.98px) and (min-width: 576px){
    div .fuel .background {
        height: 450px;
    }
    
}
@media (max-width: 991.98px) {
    div .fuel article .content{
        width: calc(100% - 40px);
    }
    .fuel .s-96 {
        font-size: 46px;
        line-height: 72px;
    }
    .fuel h2.s-4871 {
        text-align: center;
        font-size: 24px;
        line-height: 36px;
    }
    .fuel h2.s-4871.mb-100{
        margin-bottom: 30px;
    }
    div .fuel .bg-18 ,
    div .fuel .bg-1f{
        padding: 50px 0px;
    }
    div .fuel .bg-18 .mb-150{
        margin-bottom: 0px;
    }
    div .content .wd-640 {
        width: 100%;
        text-align: center;
    }
    div .fuel article .item-img {
       margin: 30px auto;
    }
    div .fuel .content .text-ic {
        width: 20px;
    }
    div .fuel .content .s-2830{
        font-size: 16px;
        line-height: 18px;
    }
    div .fuel .content .s-2830 p,
    div .fuel .content .s-2830 span{
        font-size: 16px !important;
        line-height: 18px !important;
    }
    div .fuel .wd-640 .col-12{
        width: fit-content;
    }
    .fuel .mb-150, .fuel .mb-100{
        margin-bottom: 0px;
    }
    div .fuel .wd-640 .mt-83{
        margin-top: 32px;
    }
    div .fuel .sm-0{
        margin-bottom: 0px;
    }
    div .fuel .content .s-24 {
        font-size: 14px;
        line-height: 16px;
    }
    div .fuel .content .m-0.sm-12{
        margin: 12px auto 0px !important;
    }
    div .fuel .content .m-0.sm-12 p{
        margin-bottom: 0px;
    }
}
@media (max-width: 767.98px) {
    div .fuel .s-6495 {
        font-size: 24px;
        line-height: 36px;
    }
    div .fuel .s-2030 {
        font-size: 14px;
        line-height: 20px;
    }
    div .fuel .s-2030 p,
    div .fuel .s-2030 span {
        font-size: 14px !important;
        line-height: 20px !important;
    }
     div .fuel .item-img {
        width: 279px;
        height: 334px;
    }
    div .bg-content .s-4050 {
        font-size: 16px;
        line-height: 20px;
    }
    .detail-pt .ch.px-4 {
        padding-right: .5rem !important;
        padding-left: .5rem !important;
    }
}
@media (max-width: 767.98px) and (min-width: 576px){
    div div .fuel .background,div .header-image {
        height: 350px;
    }
}

@media (max-width: 575.98px) {
    div .fuel article .background {
        height: 150px;
    }
    div .fuel article .background h1{
        font-size: 24px;
        line-height: 36px;
    }
    div  .fuel article .item-img {
        width: 279px;
        height: 334px;
    }
    div .fuel .content .s-2830 {
        text-align: left;
    }
    div div .fuel .background,div .header-image {
        height: 150px;
    }
    .fuel .s-96 {
        font-size: 28px;
        line-height: 41px;
    }
    .fuel .s-2840 {
        font-size: 16px;
        line-height: 20px;
    }
    div .bg-content .s-96 {
        font-size: 28px;
        line-height: 41px;
    }
    div .bg-content .s-6495 {
        font-size: 24px;
        line-height: 36px;
    }
 }