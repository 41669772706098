.privacy section{
    width: 85%;
    max-width: 1320px;
    margin: auto;
    padding: 250px 0px 150px;
}
.s-2426{
    font-size: 24px;
    line-height: 26px;
}
.s-3235{
    font-size: 32px;
    line-height: 35px;
}
.s-3240{
    font-size: 32px;
    line-height: 40px;
}
.s-6495{
    font-size: 64px;
    line-height: 95px;
}
.ma-100{margin: 0px auto 100px;}
.mb-100{margin-bottom: 100px;}
.mb-50{
    margin-bottom: 50px;
}
.mb-30{margin-bottom: 30px;}
.mb-20{
    margin-bottom: 20px;
}

.privacy .content-privacy .col-12{
    width: 100% !important;
}
.privacy .Din-400 {
    font-family: D-DIN-Exp , sans-serif !important;
    font-weight: 400 !important;
}
.content-privacy .c-ff {
    color: #FFFFFF !important;
}
@media (min-width: 1400px) {
    .content-privacy .s-2426 {
        font-size: 24px !important;
        line-height: 26px !important;
    }
    .content-privacy .s-3235 {
        font-size: 32px !important;
        line-height: 35px !important;
    }

}

@media (max-width: 767.98px){
.privacy .s-2426 {
    font-size: 14px !important;
    line-height: 16px !important;
}
.privacy .s-3235 {
    font-size: 16px !important;
    line-height: 24px !important;
}
.privacy .c-ff.mb-50, .privacy .mb-100 {
    margin-bottom: 30px !important;
}
}

@media (max-width: 1399.98px) { 
    .privacy section {
        width: 85%;
        max-width: 1320px;
        margin: auto;
        padding: 120px 0px 100px;
    }
    .privacy .s-6495 {
        font-size: 34px;
        line-height: 46px;
        margin-bottom: 50px;
    }
    .privacy .s-2426 {
        font-size: 18px;
        line-height: 24px;
    }
    .privacy .s-3235 {
        font-size: 26px;
        line-height: 30px;
    }
    .privacy .c-ff.mb-50, .privacy .mb-100{
        margin-bottom: 40px;
    }
}

@media (max-width: 767.98px) {
    div .privacy section {
        width: calc(85%);
        max-width: 520px;
        padding: 50px 0px;
    }
    .privacy .s-6495 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 50px;
    }
    .privacy .s-2426 {
        font-size: 14px;
        line-height: 16px;
    }
    .privacy .s-3235 {
        font-size: 16px;
        line-height: 24px;
    }
    .privacy .c-ff.mb-50, .privacy .mb-100{
        margin-bottom: 30px;
    }
}

@media (max-width: 575.98px) { 
    div .privacy section {
        width: calc(100% - 40px);
    }
}