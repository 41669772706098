/* *{
    transition: all .3s;
} */
.card-about-us{
    border-radius: 10px;
    background-color: #000;
    width: 406px;
}
.card-about-us img{
    border-radius: 10px 10px 0px 0px;
   
}
.card-about-us .p-40{
    padding: 40px;
    border-radius: 0px 10px 10px 0px;
}
.card-about-us p{
    margin-bottom: 20px;
}
.card-about-us.m-50{
    margin: 0px 50px;
}
.mt-34{margin-top: 34px;}
.mt-50{
    margin-top: 50px;
}
.mt-52{
    margin-top: 52px;
}
.modal-about-us{
    width: 1282px;
    height: 660px;
    background-color: #181818;
    padding: 30px;
    box-sizing: border-box;
}
.modal-about-us .close{
    width: 50px;
    height: 50px;
}
.modal-about-us .justify-content-between{
    padding: 0px 50px 0px;
    box-sizing: border-box;
    height: 500px;
}
.modal-about-us .img-modal{
    width: 462px;
    height: 100%;
    object-fit: cover;
}
.modal-about-us .c-modal{
    width: 560px;
    margin: auto 0px auto auto;
}
.mb-14{margin-bottom: 14px;}
.mb-30{margin-bottom: 30px;}
.modal-about-us p{
    overflow: auto;
}
@media  (min-width: 1400px) {
    .modal-about-us p{
        height: 435px;
    } 
}

@media (max-width: 1399.98px)and (min-width: 992px) {
.about-us .modal-about-us {
    width: 950px;
    height: 500px;
}
.modal-about-us .s-3235 {
    font-size: 24px;
    line-height: 28px;
}
.modal-about-us .s-2426 {
    font-size: 18px;
    line-height: 22px;
}
.modal-about-us .c-modal,
.modal-about-us .img-modal {
    width: calc(50% - 20px);
}
div .modal-about-us p{
    height: 360px;
} 
div .card-about-us .p-40 {
    padding: 30px;
}
div .card-about-us{
    width: 280px;
}
div .s-2426 {
    font-size: 20px;
    line-height: 24px;
}
div .s-4871 {
    font-size: 40px;
    line-height: 61px;
}
}

@media (min-width: 992px) {
    .about-us.fuel .mt-83{
        margin-top: 54px;
    }
}
@media (max-width: 1399.98px) { 
.about-us .s-2830 {
        font-size: 20px;
        line-height: 24px;
    }
    .about-us .s-2830 p, .about-us .s-2830 span {
        font-size: 20px !important;
        line-height: 24px !important;
    }
    .about-us .s-24 {
        font-size: 18px;
        line-height: 20px;
    }
    div .card-about-us.m-50 {
        margin: 0px 25px;
    }
   
    div .card-about-us p {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
    }
    div .s-2022 {
        font-size: 16px;
        line-height: 18px;
    }
    div .s-2022 p, div .s-2022 span{
        font-size: 16px !important;
        line-height: 18px !important;
    }
    .about-us .modal-about-us .close {
        width: 25px;
        height: 25px;
    }
    .about-us .modal-about-us .justify-content-between {
        padding: 0px 0px 0px;
        height: calc(100% - 30px);
    }
    .about-us .content .mb-150 {
        margin-bottom: 50px;
    }
}

@media (max-width: 991.98px)and (min-width: 768px) {
    .about-us .modal-about-us {
        width: 720px;
        height: 450px;
    }
    .modal-about-us .s-3235 {
        font-size: 18px;
        line-height: 20px;
    }
    .modal-about-us .s-2426 {
        font-size: 16px;
        line-height: 18px;
    }
    .modal-about-us .c-modal,
    .modal-about-us .img-modal {
        width: calc(50% - 20px);
    }
    section p{
        max-width: 650px;
    }
    }
@media (max-width: 991.98px) {
    div .about-us .card-about-us {
        margin: auto;
        /* width: calc(100% - 60px); */
        /* max-width: 400px; */
    }
    div .about-us .card-about-us.m-50{
        margin: 30px auto;
    }
}

@media (max-width: 767.98px) {

.about-us .modal-about-us {
    width: 350px;
    padding: 20px;
    margin: auto;
    height: 450px;
    overflow: hidden;
}
.about-us .modal-about-us .justify-content-between {
    height: 390px;
    margin-top: 10px;
    overflow: auto;
}
.about-us .modal-about-us .justify-content-between::-webkit-scrollbar{
    width: 0px;
    height: 0px;
  }
.about-us .modal-about-us .img-modal {
    width: 100%;
    height: 230px;
    margin: 14px auto 30px;
}
.modal-about-us .s-3235 {
    font-size: 16px;
    line-height: 17px;
}
.modal-about-us .s-2426 {
    font-size: 14px;
    line-height: 16px;
}
.about-us .modal-about-us .c-modal {
    width: 100%;
    margin: 0px auto;
}
div .about-us .card-about-us {
    width: calc(100% - 60px);
    max-width: 315px;
}
}

@media (max-width: 575.98px) { 
.about-us .card-about-us .p-40 {
    padding: 20px;
    border-radius: 0px 10px 10px 0px;
}
.about-us .card-about-us p {
    font-size: 16px;
    line-height: 17px;
}
.about-us .card-about-us a{
    font-size: 14px;
    line-height: 15px;
}

}